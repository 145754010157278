import React, { useEffect, useRef, useState } from "react";
import FaqSection from "../components/HTML/RetirementPlanning/FaqSection";
import FinancialReport from "../components/HTML/RetirementPlanning/FinancialReport";
import HeaderSection from "../components/HTML/RetirementPlanning/HeaderSection";
import HowWeWorkSection from "../components/HTML/RetirementPlanning/StepsFinancialPlan";
import YoutubevideoSection from "../components/HTML/RetirementPlanning/YoutubevideoSection";
import SecuritySection from "../components/HTML/SecuritySection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/advisor-min.jpg";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import AppointmentBox from "../components/Pages/Calendly";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie'
const RetirementPlanning = () => {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  const HowWeWorkSectionRef = useRef(null); 
  useEffect(() => {
    function extractParametersFromURL() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSourceParam = urlSearchParams.get("utm_source");
      const tagvalParam = urlSearchParams.get("tags");

      if (utmSourceParam || tagvalParam) {
        setUtmSource(utmSourceParam);
        setTagval(tagvalParam);
        setPageurl(window.location.pathname);
        const cookieOptions = { expires: 0.0208 };
        // const cookieOptions = { expires: 0.00139 };
        Cookies.set("utm_source", utmSourceParam || "", cookieOptions);
        Cookies.set("tagval", tagvalParam || "", cookieOptions);
        Cookies.set("pageurl", window.location.pathname, cookieOptions);
      } else {
        const storedUtmSource = Cookies.get("utm_source") || null;
        const storedTagval = Cookies.get("tagval") || null;
        const storedPageurl = Cookies.get("pageurl") || null;

        setUtmSource(storedUtmSource);
        setTagval(storedTagval);
        setPageurl(storedPageurl);
      }
    }

    extractParametersFromURL();
    window.addEventListener("popstate", extractParametersFromURL);

    return () => {
      window.removeEventListener("popstate", extractParametersFromURL);
    };
  }, [window.location.search]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!Cookies.get("utm_source") && !Cookies.get("tagval") && !Cookies.get("pageurl")) {
        setUtmSource(utmSource);
        setTagval(null);
        setPageurl(null);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);
console.log("utmSource:", utmSource, "tagval:", tagval, "pageurl:", pageurl);
  useEffect(() => {
    if (location.hash === "#HowWeWorkSection" && HowWeWorkSectionRef.current) {
      HowWeWorkSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <Fullpage>
      <HeaderSection />
      <p style={{height : "6rem"}} ref={HowWeWorkSectionRef}></p>
      <HowWeWorkSection />
      <FinancialReport />
      <YoutubevideoSection />
      <SecuritySection />
      <section style={{

      }} id="book-appointment">

        {utmSource && tagval ? (
          <AppointmentBox
            extraParams={{ "utm_source": utmSource, "service": 98 }}
            eventCode={tagval}
            serviceName="Retirement Planning"
            eventUrl={"https://calendly.com/fintoo/15-minutes-consultation-call-retirement-planning?hide_event_type_details=1"}
            planId = "20"
          />
        ) : (
          <AppointmentBox
            eventCode={'Callback_mintyApp_10'}
            serviceName="Retirement Planning"
            eventUrl={"https://calendly.com/fintoo/15-minutes-consultation-call-retirement-planning?hide_event_type_details=1"}
            planId = "20"
          />
        )}
      </section>
      <FaqSection />
    </Fullpage>
  );
};
export default RetirementPlanning;
