import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  DMF_GET_MF_TRANSACTIONS_API_URL,
  IS_DIRECT,
} from "../../../../constants";
import {
  getUserId,
  getItemLocal,
  fetchEncryptData,
  indianRupeeFormat,
  fetchData,
} from "../../../../common_utilities";
import moment from "moment";
import style from "./style.module.css";

const StepMessageComponent = ({ status, message }) => {
  const statusColor =
    status === "PENDING"
      ? { primary: "orange", secondary: "#ffa50014" }
      : { primary: "red", secondary: "#ff000008" };

  return (
    <div
      className={`${style.stepMessageContainer}`}
      style={{
        border: `1px solid ${statusColor.primary}`,
        background: `${statusColor.secondary}`,
      }}
    >
      {message}
    </div>
  );
};

const Step = ({
  stepNo,
  data,
  isLast,
  lineColorStatus,
  mergeStepData,
  bankName,
  successDatetime,
  installmentDate,
}) => {
  const getAnimationNameForBubble = (status) => {
    switch (status ?? "".toLowerCase()) {
      case "autocreate":
      // return 'bubble_autopatcreate_key_frame'
      case "approved":
      // return 'bubble_approved_key_frame'
      case "success":
        return "bubble_success_key";
      case "installment":
        return "bubble_installment_key";
      case "pending":
        return "bubble_pending_key";
      case "failed":
        return "bubble_failed_key";
      default:
        return "";
    }
  };

  const getBubbleContent = (status) => {
    // console.log('nil67', status, status??''.toLowerCase());
    // return '✓';
    switch (status ?? "".toLowerCase()) {
      case "autocreate":
      case "approved":
      case "success":
      case "installment":
        return "✓";
      case "pending":
        return "!";
      case "failed":
        return "𐄂";
      default:
        return "✓";
    }
  };

  return (
    <div
      className={`${style.stepContainer} ${
        mergeStepData?.status == "onhold" ? "" : style.status_found_box
      }`}
    >
      <div className={`${style.stepElementsContainer}`}>
        <div style={{ position: "relative" }}>
          <div
            style={{
              marginLeft: `${isLast ? "1px" : ""}`,
              paddingLeft: `${isLast ? "1px" : ""}`,
            }}
            className={`${
              mergeStepData?.status == "onhold" ? "" : style.status_found
            } ${style.bubble} ${
              style[getAnimationNameForBubble(mergeStepData?.status)]
            }`}
          ></div>
          <div
            style={{ padding: `${isLast ? "0 0 2px 2px " : ""}` }}
            className={`${style.bubble_content}`}
          >
            {getBubbleContent(mergeStepData?.status)}
          </div>
        </div>
        {!isLast ? (
          <div style={{ width: "2px", flex: "1", background: "#D3D3D3" }}>
            <div
              className={`${style.line} ${
                style[
                  getAnimationNameForBubble(mergeStepData?.status) + "_line"
                ]
              }`}
              style={{
                animationDelay: `${stepNo == 0 ? "0s" : `${stepNo * 2}s`}`,
              }}
            ></div>
          </div>
        ) : null}
      </div>
      <div style={{ padding: `0 0 ${isLast ? "0" : "3rem"} 0` }}>
        <div className={`${style.stepText}`}>{data.statusText}</div>
        <div className={`${style.stepBankname}`}>{data.statusDetails}</div>
        {/* {
                    stepNo == 1 ?
                         : null
                }
                {
                    stepNo == 2 ?
                        <div className={`${style.stepBankname}`}>{data.successDatetime}</div> : null
                }
                {
                    stepNo == 3 ?
                        <div className={`${style.stepBankname}`}>{data.installmentDate}</div> : null
                } */}
      </div>
    </div>
  );
};

const StepComponent = () => {
  const [error, setError] = useState(false);
  const [mfTransactionsteps, setMfTransactionSteps] = useState([]);
  const transaction_id = useParams().transaction_id;
  const [mergeStepData, setMergeStepData] = useState([{}, {}, {}, {}]);

  const transactionSteps = async () => {
    try {
      let payload = {
        url: DMF_GET_MF_TRANSACTIONS_API_URL,
        method: "post",
        data: {
          user_id: "" + getUserId(),
          transaction_id: transaction_id,
        },
      };

      let results = await fetchEncryptData(payload);
      console.log("nil3", results.data.transaction_data);
      if (
        results.error_code == 100 &&
        Array.isArray(results?.data?.transaction_data)
      ) {
        setMfTransactionSteps(results.data.transaction_data);
        let transactionData = results.data.transaction_data[0];
        let newMergeStepData = [...mergeStepData];
        switch (Number(transactionData?.cart_purchase_type)) {
          case 1:
            newMergeStepData[0]["status"] = "approved";
            newMergeStepData[1]["status"] = "onhold";
            newMergeStepData[2]["status"] = "onhold";

            if (transactionData?.order_status.toLowerCase() == "success") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "approved";
            }
            if (transactionData?.order_status.toLowerCase() == "pending") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "pending";
            }
            if (transactionData?.order_status.toLowerCase() == "failed") {
              newMergeStepData[1]["status"] = "failed";
            }

            if (transactionData?.payment_status.toLowerCase() == "success") {
              newMergeStepData[2]["status"] = "approved";
            }else if (transactionData?.payment_status.toLowerCase() == "pending") {
              newMergeStepData[2]["status"] = "pending";
            }else if (transactionData?.payment_status.toLowerCase() == "failed") {
              newMergeStepData[2]["status"] = "failed";
            }

            break;
          case 2:
            newMergeStepData[0]["status"] = "approved";
            // newMergeStepData[1]["status"] = "onhold";
            newMergeStepData[2]["status"] = "onhold";
            newMergeStepData[3]["status"] = "onhold";

            if (transactionData?.mandate_status.toLowerCase() == "approved") {
              newMergeStepData[1]["status"] = "approved";
            }else if(transactionData?.mandate_status.toLowerCase() == "failed") {
              newMergeStepData[1]["status"] = "failed";
            }else if(transactionData?.mandate_status.toLowerCase() == "pending") {
              newMergeStepData[1]["status"] = "pending";
            }

            if (transactionData?.order_status.toLowerCase() == "success") {
              newMergeStepData[2]["status"] = "approved";
            }else if (transactionData?.order_status.toLowerCase() == "pending") {
              newMergeStepData[2]["status"] = "pending";
            }else if (transactionData?.order_status.toLowerCase() == "failed") {
              newMergeStepData[2]["status"] = "failed";
            }
            
            if (transactionData?.payment_status.toLowerCase() == "success") {
              newMergeStepData[3]["status"] = "approved";
            }else if (transactionData?.payment_status.toLowerCase() == "pending") {
              newMergeStepData[3]["status"] = "pending";
            }else if (transactionData?.payment_status.toLowerCase() == "failed") {
              newMergeStepData[3]["status"] = "failed";
            }
            break;
          case 4:
            console.log("nil453");
            newMergeStepData[0]["status"] = "approved";
            newMergeStepData[1]["status"] = "onhold";
            newMergeStepData[2]["status"] = "onhold";
            if (transactionData?.order_status.toLowerCase() == "success") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "approved";
              newMergeStepData[2]["status"] = "approved";
            } else if (
              transactionData?.order_status.toLowerCase() == "failed"
            ) {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "failed";
              newMergeStepData[2]["status"] = "failed";
            } else if (
              transactionData?.order_status.toLowerCase() == "pending"
            ) {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "pending";
              newMergeStepData[2]["status"] = "onhold";
            }
            break;
          case 5:
            console.log("nil453");
            newMergeStepData[0]["status"] = "approved";
            newMergeStepData[1]["status"] = "onhold";
            newMergeStepData[2]["status"] = "onhold";
            if (transactionData?.order_status.toLowerCase() == "success") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "approved";
              newMergeStepData[2]["status"] = "approved";
            } else if (
              transactionData?.order_status.toLowerCase() == "failed"
            ) {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "failed";
              newMergeStepData[2]["status"] = "failed";
            }
            if (transactionData?.order_status.toLowerCase() == "pending") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "pending";
            }
            break;

          case 6:
            console.log("nil453");
            newMergeStepData[0]["status"] = "approved";
            newMergeStepData[1]["status"] = "onhold";
            newMergeStepData[2]["status"] = "onhold";
            if (transactionData?.order_status.toLowerCase() == "success") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "approved";
              newMergeStepData[2]["status"] = "approved";
            }
            if (transactionData?.order_status.toLowerCase() == "failed") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "failed";
              newMergeStepData[2]["status"] = "failed";
            }
            if (transactionData?.order_status.toLowerCase() == "pending") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "pending";
            }
            break;
          case 7:
            console.log("nil453");
            newMergeStepData[0]["status"] = "approved";
            newMergeStepData[1]["status"] = "onhold";
            newMergeStepData[2]["status"] = "onhold";
            if (transactionData?.order_status.toLowerCase() == "success") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "approved";
              newMergeStepData[2]["status"] = "approved";
            } else if (
              transactionData?.order_status.toLowerCase() == "failed"
            ) {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "failed";
              newMergeStepData[2]["status"] = "failed";
            }
            if (transactionData?.order_status.toLowerCase() == "pending") {
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "pending";
            }
            break;
          case 8:
              console.log("STP in");
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "onhold";
              newMergeStepData[2]["status"] = "onhold";
              if (transactionData?.order_status.toLowerCase() == "success") {
                newMergeStepData[0]["status"] = "approved";
                newMergeStepData[1]["status"] = "approved";
                newMergeStepData[2]["status"] = "approved";
              }
              if (transactionData?.order_status.toLowerCase() == "failed") {
                newMergeStepData[0]["status"] = "approved";
                newMergeStepData[1]["status"] = "failed";
                newMergeStepData[2]["status"] = "failed";
              }
              if (transactionData?.order_status.toLowerCase() == "pending") {
                newMergeStepData[0]["status"] = "approved";
                newMergeStepData[1]["status"] = "pending";
              }
              break;
          case 9:
              console.log("STP out");
              newMergeStepData[0]["status"] = "approved";
              newMergeStepData[1]["status"] = "onhold";
              newMergeStepData[2]["status"] = "onhold";
              if (transactionData?.order_status.toLowerCase() == "success") {
                newMergeStepData[0]["status"] = "approved";
                newMergeStepData[1]["status"] = "approved";
                newMergeStepData[2]["status"] = "approved";
              } else if (
                transactionData?.order_status.toLowerCase() == "failed"
              ) {
                newMergeStepData[0]["status"] = "approved";
                newMergeStepData[1]["status"] = "failed";
                newMergeStepData[2]["status"] = "failed";
              }
              if (transactionData?.order_status.toLowerCase() == "pending") {
                newMergeStepData[0]["status"] = "approved";
                newMergeStepData[1]["status"] = "pending";
              }
              break;
        }

        console.log(
          "nil666",
          transactionData?.cart_purchase_type,
          newMergeStepData
        );

        setMergeStepData([...newMergeStepData]);
      } else {
        if (results.error_code == 102) {
          setMfTransactionSteps([]);
        }
      }
    } catch (e) {
      console.error("err77", e);
      console.error("Something went wrong");
    }
  };

  useEffect(() => {
    try {
      document
        .querySelector(`.${style["status_found_blink"]}`)
        .classList.remove(style["status_found_blink"]);
    } catch {}
    try {
      let ele = document.querySelectorAll(`.${style["status_found"]}`);
      ele.forEach((v, i) => {
        if (i == ele.length - 1) {
          v.classList.add(style["status_found_blink"]);
        }
      });
      ele = document.querySelectorAll(
        `.${style["status_found_box"]} .${style["line"]}`
      );
      // console.log('nil865', ele);
      ele.forEach((v, i) => {
        v.style.animationDelay = 2 * i + "s";
        // v.style.border = "solid 2px";
      });
    } catch {}
  }, [mergeStepData]);

  useEffect(() => {
    transactionSteps();
  }, []);

  const showOrderNote = (mergeStepData) => {
    try {
      // check for failed order
      if (mergeStepData.some((v) => v?.status == "failed")) {
        // console.log("inside mergeStepData");
        return false;
      } else if (mergeStepData.every((v) => v?.status == "approved")) {
        return false;
      } else {
        return true;
      }
      // return true;
    } catch {
      return true;
    }
  };

  console.log("mergeStepData", mergeStepData);

  return (
    <>
      <br />
      {mfTransactionsteps.length > 0 &&
        mfTransactionsteps.map((val) => {
          return (
            <Fragment key={val.cart_purchase_type + "_purchasetype"}>
              {val.cart_purchase_type == 2 && (
                <div className={`${style["step-main-container"]}`}>
                  <Step
                    mergeStepData={mergeStepData[0]}
                    data={{ statusText: "Auto-pay Created",
                        statusDetails: val.bank_name + " ( XXXXXX" + val.bank_acc_no.substr(val.bank_acc_no.length - 4) +")", }}
                  />
                  <Step
                    mergeStepData={mergeStepData[1]}
                    data={{
                      statusText: "Auto-pay " + val.mandate_status
                    }}
                  />
                  <Step
                    mergeStepData={mergeStepData[2]}
                    data={{
                      statusText: "SIP Registration Success At BSE",
                    }}
                  />
                  <Step
                    mergeStepData={mergeStepData[3]}
                    data={{
                      statusText: "Payment of 1st SIP Installment",
                      statusDetails: val.bse_order_status === "INVALID"
                      ? `Reason: ${val.bse_order_status_remark}`
                      : moment(val.sip_start_date).format("DD-MM-YYYY"),
                    }}
                    isLast={true}
                  />
                </div>
              )}

              {val.cart_purchase_type == 1 && (
                <div className={`${style["step-main-container"]}`}>
                  <Step
                    mergeStepData={mergeStepData[0]}
                    data={{ statusText: "Order Placed" }}
                  />
                  <Step
                    mergeStepData={mergeStepData[1]}
                    data={{
                      statusText: "Order Under Procesing At BSE",
                    }}
                  />
                  <Step
                    isLast={true}
                    mergeStepData={mergeStepData[2]}
                    data={{
                      statusText: "Units allotted by AMC",
                      statusDetails: val.bse_order_status === "INVALID"
                      ? `Reason: ${val.bse_order_status_remark}`
                      : '',
                    }}
                  />
                </div>
              )}

              {val.cart_purchase_type == 4 && (
                <div className={`${style["step-main-container"]}`}>
                  <Step
                    mergeStepData={mergeStepData[0]}
                    data={{ statusText: "Redeem Order Placed" }}
                  />
                  <Step
                    mergeStepData={mergeStepData[1]}
                    data={{
                      statusText: "Redeem Order Under Process At BSE",
                    }}
                  />
                  <Step
                    isLast={true}
                    mergeStepData={mergeStepData[2]}
                    data={{
                      statusText: "Units Redeemed",
                    }}
                  />
                </div>
              )}
              {val.cart_purchase_type == 5 && (
                <div className={`${style["step-main-container"]}`}>
                  <Step
                    mergeStepData={mergeStepData[0]}
                    data={{ statusText: "Switch Order Placed" }}
                  />
                  <Step
                    mergeStepData={mergeStepData[1]}
                    data={{
                      statusText: "Switch Order Under Process At BSE",
                    }}
                  />
                  <Step
                    isLast={true}
                    mergeStepData={mergeStepData[2]}
                    data={{
                      statusText: "Switch Success",
                    }}
                  />
                </div>
              )}
              {val.cart_purchase_type == 6 && (
                <div className={`${style["step-main-container"]}`}>
                  <Step
                    mergeStepData={mergeStepData[0]}
                    data={{ statusText: "Switch Order Placed" }}
                  />
                  <Step
                    mergeStepData={mergeStepData[1]}
                    data={{
                      statusText: "Switch Order Under Process At BSE",
                    }}
                  />
                  <Step
                    isLast={true}
                    mergeStepData={mergeStepData[2]}
                    data={{
                      statusText: "Switch Success",
                    }}
                  />
                </div>
              )}
              {val.cart_purchase_type == 7 && (
                <div className={`${style["step-main-container"]}`}>
                  <Step
                    mergeStepData={mergeStepData[0]}
                    data={{ statusText: "SWP Order Placed" }}
                  />
                  <Step
                    mergeStepData={mergeStepData[1]}
                    data={{
                      statusText: "SWP Order Under Process At BSE",
                    }}
                  />
                  <Step
                    isLast={true}
                    mergeStepData={mergeStepData[2]}
                    data={{
                      statusText: "SWP Success",
                    }}
                  />
                </div>
              )}
              {val.cart_purchase_type == 8 && (
                <div className={`${style["step-main-container"]}`}>
                  <Step
                    mergeStepData={mergeStepData[0]}
                    data={{ statusText: "STP Order Placed" }}
                  />
                  <Step
                    mergeStepData={mergeStepData[1]}
                    data={{
                      statusText: "STP Order Under Process At BSE",
                    }}
                  />
                  <Step
                    isLast={true}
                    mergeStepData={mergeStepData[2]}
                    data={{
                      statusText: "STP Order Success",
                      statusDetails: val.bse_order_status === "INVALID"
                      ? `Reason: ${val.bse_order_status_remark}`
                      :'',
                    }}
                  />
                </div>
              )}
              {val.cart_purchase_type == 9 && (
                <div className={`${style["step-main-container"]}`}>
                  <Step
                    mergeStepData={mergeStepData[0]}
                    data={{ statusText: "STP Order Placed" }}
                  />
                  <Step
                    mergeStepData={mergeStepData[1]}
                    data={{
                      statusText: "STP Order Under Process At BSE",
                    }}
                  />
                  <Step
                    isLast={true}
                    mergeStepData={mergeStepData[2]}
                    data={{
                      statusText: "STP Order Success",
                      statusDetails: val.bse_order_status === "INVALID"
                      ? `Reason: ${val.bse_order_status_remark}`
                      : '',
                    }}
                  />
                </div>
              )}
              {mergeStepData.filter(v=> v.status == "pending").length > 0 && <StepMessageComponent
                status={"PENDING"}
                message={
                  "It takes 4-5 working days for the AMC to confirm orders."
                }
              />}
            </Fragment>
          );
        })}
      {/* <div>
                <Step stepNo={0} data={{ statusText: 'Payment failed', status: 'FAILED' }} lineColorStatus={'FAILED'} />
                <Step stepNo={1} data={{ statusText: 'Failed', status: 'FAILED' }} isLast={true} />
                {
                    <StepMessageComponent status={'FAILED'} message={'If amount was debited from your account, it will be reverted within 4-5 working days'} />
                }
            </div> */}
    </>
  );
};

export default StepComponent;
