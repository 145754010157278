import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useLocation } from "react-router-dom";

const VideosSection = () => {

  return (
    <section className={`${styles.section}`} id="oa-y-9">
      <div className={`${styles.container} container`}>
        <div className={`text-center ${styles.GlobalText}`}>
        Trusted by Thousands for Expert Investment and Financial Advisory services!
        </div>

        <div style={{ width: '100%', margin: '0 auto', display: 'flex', flexWrap: 'wrap', gap: '1rem', alignItems: 'center', justifyContent: 'center' }}>
          <iframe
            className={`${styles.videoIframe}`}
            src='https://www.youtube.com/embed/akCsYoRcVfU?feature=oembed&rel=0&mute=0&loop=0&controls=1'>
          </iframe>

          <iframe
            className={`${styles.videoIframe}`}
            src='https://www.youtube.com/embed/3-MGUU4QWOc?feature=oembed&rel=0&mute=0&loop=0&controls=1'>
          </iframe>

          <iframe
            className={`${styles.videoIframe}`}
            src='https://www.youtube.com/embed/Bq7vHocVG14?feature=oembed&rel=0&mute=0&loop=0&controls=1'>
          </iframe>
        </div>

      </div>
    </section>
  );
};

export default VideosSection;
